import { render, staticRenderFns } from "./health.vue?vue&type=template&id=18d0885a&scoped=true&"
import script from "./health.vue?vue&type=script&lang=js&"
export * from "./health.vue?vue&type=script&lang=js&"
import style0 from "./health.vue?vue&type=style&index=0&id=18d0885a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18d0885a",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QSpace,QMenu,QSpinner,QDialog});qInstall(component, 'directives', {ClosePopup});
