import { render, staticRenderFns } from "./term.vue?vue&type=template&id=4f45ab88&scoped=true&"
import script from "./term.vue?vue&type=script&lang=js&"
export * from "./term.vue?vue&type=script&lang=js&"
import style0 from "./term.vue?vue&type=style&index=0&id=4f45ab88&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f45ab88",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QSpace,QSpinner,QDialog});
