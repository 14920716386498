import { render, staticRenderFns } from "./tab-card.vue?vue&type=template&id=2b7b7a8e&scoped=true&"
import script from "./tab-card.vue?vue&type=script&lang=js&"
export * from "./tab-card.vue?vue&type=script&lang=js&"
import style0 from "./tab-card.vue?vue&type=style&index=0&id=2b7b7a8e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b7b7a8e",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QImg,QCardSection});
